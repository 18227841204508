import { Container, Grid, Typography, Box } from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { PageHeader } from '../components/PageHeader';
import { StyledAI } from '../components/StyledAI';

const MotionBox = styled(motion.div)`
  width: 100%;
`;

const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(4),
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 6px 25px rgba(0, 0, 0, 0.15)',
  },
}));

const StyledSubheading = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1.75rem',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(2),
  color: theme.palette.text.primary,
}));

interface FadeInWhenVisibleProps {
  children: React.ReactNode;
}

const FadeInWhenVisible: React.FC<FadeInWhenVisibleProps> = ({ children }) => {
  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </MotionBox>
  );
};

const About = () => {
  return (
    <Box>
      <PageHeader
        title="Building Tomorrow's AI Legacy, Today"
        subtitle="Where Precision Meets Possibility"
      />
      
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FadeInWhenVisible>
              <StyledSection>
                <StyledSubheading variant="h4">
                  The CHHAAI Origin
                </StyledSubheading>
                <Typography variant="body1" paragraph>
                  Two visionaries saw a gap in India's AI landscape. While others focused on developing AI, we recognized a bigger challenge: making AI truly transformative for businesses of all sizes. Born from the fusion of commanding operational excellence and global impact expertise, CHHAAI emerged as a different kind of AI company.
                </Typography>
                <Typography variant="body1" paragraph>
                  Our founders brought together seemingly contrasting worlds - a visionary commander who orchestrated mission-critical operations with unwavering precision, and a pioneering thought leader who transformed complex knowledge into accessible wisdom for hundreds of thousands of learners worldwide. This unique fusion created something unprecedented: an AI solutions company that combines execution excellence with unparalleled accessibility.
                </Typography>
              </StyledSection>
            </FadeInWhenVisible>
          </Grid>

          <Grid item xs={12}>
            <FadeInWhenVisible>
              <StyledSection>
                <StyledSubheading variant="h4">
                  Why We Exist
                </StyledSubheading>
                <Typography variant="body1" paragraph>
                  The business world faces a critical dilemma. AI adoption is no longer optional, yet traditional implementation approaches consistently fall short, creating an ever-widening gap between AI's potential and its practical application. This is where CHHAAI steps in. We architect AI solutions differently, transforming complex technology into accessible tools that deliver immediate impact through our unique blend of military-precision planning and educational clarity.
                </Typography>
              </StyledSection>
            </FadeInWhenVisible>
          </Grid>

          <Grid item xs={12}>
            <FadeInWhenVisible>
              <StyledSection>
                <StyledSubheading variant="h4">
                  The CHHAAI Foundation
                </StyledSubheading>
                <Typography variant="body1" paragraph>
                  Our strength flows from the fusion of precision, purpose, and partnership. Every solution undergoes rigorous testing with military-grade protocols, ensuring flawless execution. Our research-driven approach creates industry-specific solutions that evolve continuously, staying ahead of tomorrow's challenges. Through transformative partnerships, we ensure our clients don't just adopt AI—they master it.
                </Typography>
              </StyledSection>
            </FadeInWhenVisible>
          </Grid>

          <Grid item xs={12}>
            <FadeInWhenVisible>
              <StyledSection>
                <StyledSubheading variant="h4">
                  Innovation in Action
                </StyledSubheading>
                <Typography variant="body1" paragraph>
                  At CHHAAI, excellence manifests through two revolutionary programs. Our Growth Partnership Program creates a unique mentorship ecosystem where experience guides potential and innovation exceeds expectations. Our Value Recognition System complements this by acknowledging the holistic impact of innovative thinking and collaborative growth, going beyond traditional metrics to foster excellence.
                </Typography>
              </StyledSection>
            </FadeInWhenVisible>
          </Grid>

          <Grid item xs={12}>
            <FadeInWhenVisible>
              <StyledSection>
                <StyledSubheading variant="h4">
                  Join the AI Revolution
                </StyledSubheading>
                <Typography variant="body1" paragraph>
                  We're not just another AI company. We're architects of transformation, builders of tomorrow, and partners in your success. Every solution we deliver carries the precision and the clarity. This is your invitation to be part of something extraordinary—a journey toward transforming tomorrow's possibilities into today's realities.
                </Typography>
                <Typography variant="body1" paragraph>
                  Your transformation begins with a conversation. Whether through a strategic consultation, partnership exploration, or joining our innovation journey, take the first step toward redefining what's possible with AI.
                </Typography>
                <Typography variant="body1" sx={{ mt: 4, fontStyle: 'italic', color: 'text.secondary' }}>
                  Where precision meets innovation, transformation follows. Welcome to CHHAAI AI Solutions—architecting tomorrow's AI, delivering today's success.
                </Typography>
              </StyledSection>
            </FadeInWhenVisible>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default About;
