import React from 'react';
import { motion } from 'framer-motion';
import { glowVariants, gradientTextVariants } from '../animations/textAnimations';
import styled from 'styled-components';

interface AnimatedTextProps {
  text: string;
  type?: 'glow' | 'gradient';
  className?: string;
  isAI?: boolean;
  inheritFontSize?: boolean;
}

const GradientText = styled(motion.span)<{ $inheritFontSize?: boolean }>`
  background: linear-gradient(90deg, #3B82F6, #6366F1, #8B5CF6, #3B82F6);
  background-size: 300% 100%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
  font-size: ${props => props.$inheritFontSize ? 'inherit' : 'auto'};
`;

const AnimatedText: React.FC<AnimatedTextProps> = ({ 
  text, 
  type = 'gradient',
  className = '',
  isAI = false,
  inheritFontSize = true
}) => {
  if (text === 'CHHAAI') {
    return (
      <React.Fragment>
        <span>CHHAAI</span>
        {' '}
        <GradientText
          variants={gradientTextVariants}
          initial="initial"
          animate="animate"
          $inheritFontSize={inheritFontSize}
          style={{ 
            fontWeight: 'bold',
            marginLeft: '0.1em'
          }}
        >
          AI
        </GradientText>
      </React.Fragment>
    );
  }

  if (!isAI) {
    return (
      <motion.span
        variants={type === 'glow' ? glowVariants : gradientTextVariants}
        initial="initial"
        animate="animate"
        className={className}
      >
        {text}
      </motion.span>
    );
  }

  // Special handling for text containing "AI"
  const parts = text.split(/(AI)/g);
  return (
    <React.Fragment>
      {parts.map((part, index) => {
        if (part === 'AI') {
          return (
            <GradientText
              key={index}
              variants={gradientTextVariants}
              initial="initial"
              animate="animate"
              $inheritFontSize={inheritFontSize}
              style={{ 
                fontWeight: 'bold',
                display: 'inline-block'
              }}
            >
              {part}
            </GradientText>
          );
        }
        return <span key={index}>{part}</span>;
      })}
    </React.Fragment>
  );
};

export default AnimatedText;
