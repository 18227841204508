import { ENDPOINTS } from '../config/config';

interface ChatMessage {
  text: string;
  isUser: boolean;
}

class ChatbotService {
  private static instance: ChatbotService;
  private apiEndpoint: string;

  private constructor() {
    // Remove any trailing slashes and ensure clean endpoint
    const baseEndpoint = ENDPOINTS.CHATBOT.replace(/\/+$/, '');
    
    // In development, use the full URL
    this.apiEndpoint = process.env.NODE_ENV === 'development' 
      ? `http://localhost:8888${baseEndpoint}`
      : baseEndpoint;
      
    console.log('Initialized ChatbotService with endpoint:', this.apiEndpoint);
    console.log('Environment:', process.env.NODE_ENV);
  }

  public static getInstance(): ChatbotService {
    if (!ChatbotService.instance) {
      ChatbotService.instance = new ChatbotService();
    }
    return ChatbotService.instance;
  }

  private async checkResponseType(response: Response): Promise<any> {
    const contentType = response.headers.get('content-type');
    console.log('Response Content-Type:', contentType);
    
    // First try to get the response as text
    const responseText = await response.text();
    console.log('Raw response:', responseText);

    // Try to parse as JSON
    try {
      return JSON.parse(responseText);
    } catch (error) {
      console.error('Failed to parse response as JSON:', error);
      throw new Error('Invalid response format from server');
    }
  }

  public async sendMessage(message: string): Promise<ChatMessage> {
    try {
      if (!message || message.trim().length === 0) {
        return {
          text: 'Please enter a message.',
          isUser: false
        };
      }

      const url = this.apiEndpoint;
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ message: message.trim() })
      };

      console.log('Sending request to:', url);
      console.log('Request options:', requestOptions);
      
      const response = await fetch(url, requestOptions);
      console.log('Response status:', response.status);
      console.log('Response headers:', Object.fromEntries(response.headers.entries()));

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Server error (${response.status}):`, errorText);
        
        if (response.status === 404) {
          console.error('404 error details:', {
            url,
            headers: response.headers,
            error: errorText
          });
          throw new Error('Unable to reach chatbot service. Please check your connection and try again.');
        }
        if (response.status === 502 || response.status === 503) {
          throw new Error('Server is temporarily unavailable. Please try again later.');
        }
        if (response.status === 429) {
          throw new Error('Too many requests. Please wait a moment and try again.');
        }

        try {
          const errorJson = JSON.parse(errorText);
          throw new Error(errorJson.error || errorJson.text || 'Server error');
        } catch {
          throw new Error(`Server error: ${response.status}`);
        }
      }

      const data = await this.checkResponseType(response);
      
      // Handle both response formats (response or text field)
      const messageText = data.text || data.response;
      if (!messageText || typeof messageText !== 'string') {
        console.error('Invalid response data:', data);
        throw new Error('Invalid response format from server');
      }

      return {
        text: messageText,
        isUser: false
      };

    } catch (error) {
      console.error('Error in sendMessage:', error);
      return {
        text: error instanceof Error ? error.message : 'An unexpected error occurred.',
        isUser: false
      };
    }
  }
}

export { type ChatMessage };
export default ChatbotService;
