import React, { useState } from 'react';
import { Box, Container, Grid, Typography, TextField, Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { subscribeNewsletter } from '../../services/emailService';
import XIcon from '../icons/XIcon';

const StyledFooter = styled('footer')(({ theme }) => ({
  background: `linear-gradient(45deg, #6B3811 0%, #8B4513 100%)`,
  color: '#F5DEB3',
  padding: theme.spacing(8, 0),
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: `linear-gradient(90deg, #8B4513, #DEB887)`,
  },
}));

const SocialIcon = styled(motion.a)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  color: '#F5DEB3',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  padding: theme.spacing(1),
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  transition: 'all 0.3s ease',
  textDecoration: 'none',
  '&:hover': {
    color: '#DEB887',
    transform: 'translateY(-2px)',
  },
  '& svg': {
    fontSize: '28px',
  },
}));

const FooterLink = styled(motion.button)(({ theme }) => ({
  color: '#F5DEB3',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  padding: theme.spacing(0.5, 1),
  transition: 'all 0.3s ease',
  '&:hover': {
    color: '#DEB887',
    transform: 'translateY(-2px)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#DEB887',
    },
    '&:hover fieldset': {
      borderColor: '#F5DEB3',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#F5DEB3',
    },
  },
  '& .MuiInputBase-input': {
    color: '#F5DEB3',
  },
  '& .MuiInputLabel-root': {
    color: '#DEB887',
    '&.Mui-focused': {
      color: '#F5DEB3',
    },
  },
}));

const FooterSection = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <Box mb={4}>
    <Typography variant="h6" component="h3" gutterBottom sx={{ color: '#F5DEB3', fontWeight: 600 }}>
      {title}
    </Typography>
    {children}
  </Box>
);

export default function Footer() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNewsletterSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) return;

    setIsSubmitting(true);
    try {
      const result = await subscribeNewsletter(email);
      
      if (result.success) {
        alert('Thank you for subscribing to our newsletter!');
        setEmail('');
      } else {
        throw new Error('Failed to subscribe');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Sorry, there was an error subscribing to the newsletter. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSocialClick = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <StyledFooter>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <FooterSection title="About CHHAAI">
              <Typography variant="body2" sx={{ mb: 2, color: '#F5DEB3' }}>
                Where military precision meets educational excellence to transform your future with AI solutions.
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <SocialIcon
                  href="https://www.linkedin.com/company/chhaai"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visit our LinkedIn page"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <LinkedInIcon />
                </SocialIcon>
                <SocialIcon
                  href="https://x.com/chhaai"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visit our X (formerly Twitter) page"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <XIcon />
                </SocialIcon>
                <SocialIcon
                  href="https://www.youtube.com/@chhaai"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visit our YouTube channel"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <YouTubeIcon />
                </SocialIcon>
                <SocialIcon
                  href="https://www.instagram.com/chhaai"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visit our Instagram page"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <InstagramIcon />
                </SocialIcon>
              </Box>
            </FooterSection>
          </Grid>

          <Grid item xs={12} md={4}>
            <FooterSection title="Quick Links">
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <FooterLink onClick={() => navigate('/about')} whileHover={{ x: 5 }}>
                  About Us
                </FooterLink>
                <FooterLink onClick={() => navigate('/solutions')} whileHover={{ x: 5 }}>
                  Solutions
                </FooterLink>
                <FooterLink onClick={() => navigate('/careers')} whileHover={{ x: 5 }}>
                  Careers
                </FooterLink>
                <FooterLink onClick={() => navigate('/contact')} whileHover={{ x: 5 }}>
                  Contact
                </FooterLink>
              </Box>
            </FooterSection>
          </Grid>

          <Grid item xs={12} md={4}>
            <FooterSection title="Stay Updated">
              <Box component="form" onSubmit={handleNewsletterSubmit}>
                <StyledTextField
                  fullWidth
                  label="Email Address"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  sx={{ mb: 2 }}
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isSubmitting}
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={isSubmitting}
                  sx={{
                    background: `linear-gradient(45deg, #8B4513 30%, #DEB887 90%)`,
                  }}
                >
                  {isSubmitting ? 'Subscribing...' : 'Subscribe'}
                </Button>
              </Box>
            </FooterSection>
          </Grid>
        </Grid>

        <Box sx={{ mt: 4, pt: 4, borderTop: '1px solid rgba(245, 245, 220, 0.1)' }}>
          <Typography variant="body2" align="center" sx={{ color: '#F5DEB3' }}>
            &copy; {new Date().getFullYear()} CHHAAI AI Solutions. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </StyledFooter>
  );
}
