import { Container, Grid, Typography, Box, Card, Button } from '@mui/material';
import { motion, HTMLMotionProps } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '../components/PageHeader';
import { StyledAI } from '../components/StyledAI';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(6),
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '& .MuiTypography-body1': {
    fontSize: '1.1rem',
    lineHeight: 1.7,
  },
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 12px 30px rgba(52, 73, 94, 0.15)',
  },
}));

const GradientTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 700,
  marginBottom: theme.spacing(3),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(2),
  color: theme.palette.text.primary,
}));

const ComparisonTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(2),
  color: theme.palette.text.primary,
}));

const HighlightedBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  background: 'linear-gradient(145deg, rgba(52, 73, 94, 0.05) 0%, rgba(52, 73, 94, 0.1) 100%)',
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  border: '1px solid rgba(52, 73, 94, 0.1)',
}));

const MotionBox = styled(motion.div)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  padding: ${({ theme }) => theme.spacing(1.5, 4)};
  border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
  font-size: 1rem;
  font-weight: 500;
`;

interface MotionButtonProps extends HTMLMotionProps<"button"> {
  variant?: 'contained' | 'outlined';
  onClick?: () => void;
}

const MotionButton = styled(motion.button)<MotionButtonProps>`
  ${({ theme, variant }) => `
    padding: ${theme.spacing(1.5, 4)};
    border-radius: ${theme.shape.borderRadius * 2}px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    border: none;
    background: ${variant === 'contained' ? theme.palette.primary.main : 'transparent'};
    color: ${variant === 'contained' ? theme.palette.primary.contrastText : theme.palette.primary.main};
    border: ${variant === 'outlined' ? `2px solid ${theme.palette.primary.main}` : 'none'};
    
    &:hover {
      background: ${variant === 'contained' ? theme.palette.primary.dark : 'rgba(83, 109, 254, 0.04)'};
    }
  `}
`;

interface FadeInWhenVisibleProps {
  children: React.ReactNode;
}

const FadeInWhenVisible: React.FC<FadeInWhenVisibleProps> = ({ children }) => {
  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </MotionBox>
  );
};

const StyledSubheading = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1.75rem',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));

const ComparisonHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontStyle: 'italic',
  fontSize: '1.25rem',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),
}));

const Solutions = () => {
  const navigate = useNavigate();

  const solutions = [
    {
      title: "Customer Experience",
      before: "Your support team struggles with repetitive queries. Response times lag. Customer satisfaction suffers.",
      after: "Our AI-powered support handles 80% of queries instantly. Your team focuses on high-value interactions. Customer satisfaction soars.",
      description: (
        <>
          <StyledSubheading variant="h5">
            The CHHAAI Solution
          </StyledSubheading>
          <Typography variant="body1" paragraph>
            Transform your customer experience with our AI-powered solutions. We integrate cutting-edge technology with your existing systems to create seamless, intelligent interactions that delight customers and empower your team.
          </Typography>
        </>
      ),
    },
    {
      title: "Business Intelligence",
      before: "Data sits unused. Opportunities slip away. Decisions rely on gut feeling.",
      after: "Real-time insights drive every decision. Market trends are spotted early. Growth becomes predictable.",
      description: (
        <>
          <StyledSubheading variant="h5">
            The CHHAAI Solution
          </StyledSubheading>
          <Typography variant="body1" paragraph>
            Unlock the full potential of your data with our AI-powered business intelligence solutions. We help you make informed decisions, identify new opportunities, and drive growth.
          </Typography>
        </>
      ),
    },
    {
      title: "Digital Presence",
      before: "Static websites. Generic content. Lost opportunities.",
      after: "Your digital presence learns, adapts, and converts. Every visitor gets a personalized experience.",
      description: (
        <>
          <StyledSubheading variant="h5">
            The CHHAAI Solution
          </StyledSubheading>
          <Typography variant="body1" paragraph>
            Elevate your digital presence with our AI-powered web solutions. We create personalized experiences that engage visitors, drive conversions, and grow your business.
          </Typography>
        </>
      ),
    },
    {
      title: "Market Opportunities",
      before: "Manual trading. Missed signals. Reactive decisions.",
      after: "Algorithmic precision. Proactive strategies. Consistent performance.",
      description: (
        <>
          <StyledSubheading variant="h5">
            The CHHAAI Solution
          </StyledSubheading>
          <Typography variant="body1" paragraph>
            Stay ahead of the market with our AI-powered trading algorithms. We help you identify opportunities, make informed decisions, and achieve consistent performance.
          </Typography>
        </>
      ),
    },
  ];

  const transformationSteps = [
    {
      title: "Discovery",
      description: "We understand your challenges, goals, and vision.",
    },
    {
      title: "Strategy",
      description: "Custom solutions designed for your specific needs.",
    },
    {
      title: "Implementation",
      description: "Military-grade precision meets educational excellence.",
    },
    {
      title: "Growth",
      description: "Continuous optimization and scaling.",
    },
  ];

  return (
    <Box>
      <PageHeader
        title="Delivering Tomorrow's Innovation, Today"
        subtitle="Transform your business with AI-powered solutions"
      />
      
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FadeInWhenVisible>
              <Typography variant="body1" paragraph>
                Imagine your business running at peak efficiency:
              </Typography>
              <Box component="ul" sx={{ pl: 2, mb: 4 }}>
                <Typography component="li" variant="body1" sx={{ mb: 1 }}>
                  Every decision backed by data.
                </Typography>
                <Typography component="li" variant="body1" sx={{ mb: 1 }}>
                  Every customer interaction personalized.
                </Typography>
                <Typography component="li" variant="body1" sx={{ mb: 1 }}>
                  Every process optimized.
                </Typography>
              </Box>
              <Typography variant="body1" paragraph>
                This isn't just a future scenario – it's your business with CHHAAI AI.
              </Typography>
            </FadeInWhenVisible>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>
              <StyledAI text="Your Challenges, Our Solutions" variant="h2" />
            </Typography>
          </Grid>

          {solutions.map((solution, index) => (
            <Grid item xs={12} md={6} key={index}>
              <FadeInWhenVisible>
                <MotionBox
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                >
                  <StyledCard>
                    <StyledSubheading variant="h4">
                      {solution.title}
                    </StyledSubheading>
                    
                    <Box sx={{ mb: 3 }}>
                      <ComparisonHeading variant="h6" gutterBottom>
                        Before:
                      </ComparisonHeading>
                      <Typography variant="body1" paragraph>
                        {solution.before}
                      </Typography>
                    </Box>

                    <Box sx={{ mb: 3 }}>
                      <ComparisonHeading variant="h6" gutterBottom>
                        After:
                      </ComparisonHeading>
                      <Typography variant="body1" paragraph>
                        {solution.after}
                      </Typography>
                    </Box>

                    <HighlightedBox>
                      {solution.description}
                    </HighlightedBox>
                  </StyledCard>
                </MotionBox>
              </FadeInWhenVisible>
            </Grid>
          ))}

          <Grid item xs={12} sx={{ mt: 6 }}>
            <Typography variant="h2" gutterBottom>
              <StyledAI text="Your Transformation Journey" variant="h2" />
            </Typography>
          </Grid>

          {transformationSteps.map((step, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <FadeInWhenVisible>
                <MotionBox
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                >
                  <StyledCard>
                    <Typography variant="h5" gutterBottom color="primary">
                      {index + 1}. {step.title}
                    </Typography>
                    <Typography variant="body1">
                      {step.description}
                    </Typography>
                  </StyledCard>
                </MotionBox>
              </FadeInWhenVisible>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 8, textAlign: 'center' }}>
          <Typography variant="h3" gutterBottom>
            <StyledAI text="Start Your Tomorrow, Today" variant="h3" />
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Schedule a transformation session. Experience the future of your business.
          </Typography>
          <Box sx={{ mt: 4, display: 'flex', gap: 3, justifyContent: 'center' }}>
            <MotionButton
              variant="contained"
              onClick={() => navigate('/contact')}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Schedule Consultation
            </MotionButton>
            <MotionButton
              variant="outlined"
              onClick={() => navigate('/case-studies')}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              View Case Studies
            </MotionButton>
          </Box>
          <Typography variant="body1" sx={{ mt: 4, fontStyle: 'italic', color: 'text.secondary' }}>
            Where military precision meets AI innovation – the CHHAAI advantage.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Solutions;
