import { Variants } from 'framer-motion';

export const glowVariants: Variants = {
  initial: {
    opacity: 1,
    textShadow: "0 0 0 rgba(79, 70, 229, 0)"
  },
  animate: {
    opacity: [1, 0.95, 1],
    textShadow: [
      "0 0 0 rgba(79, 70, 229, 0)",
      "0 0 30px rgba(79, 70, 229, 0.7)",
      "0 0 0 rgba(79, 70, 229, 0)"
    ],
    transition: {
      duration: 2,
      repeat: Infinity,
      repeatType: "reverse",
      ease: "easeInOut"
    }
  }
};

export const floatVariants: Variants = {
  initial: {
    y: 0,
    scale: 1
  },
  animate: {
    y: [-2, 2, -2],
    scale: [1, 1.02, 1],
    transition: {
      duration: 4,
      repeat: Infinity,
      repeatType: "reverse",
      ease: "easeInOut"
    }
  }
};

export const gradientTextVariants: Variants = {
  initial: {
    backgroundPosition: "0% 50%",
    backgroundSize: "300% 300%"
  },
  animate: {
    backgroundPosition: ["0% 50%", "100% 50%", "0% 50%"],
    transition: {
      duration: 4,
      repeat: Infinity,
      repeatType: "reverse",
      ease: "easeInOut"
    }
  }
};

export const letterSpacingVariants: Variants = {
  initial: {
    letterSpacing: "normal"
  },
  animate: {
    letterSpacing: ["normal", "0.1em", "normal"],
    transition: {
      duration: 3,
      repeat: Infinity,
      repeatType: "reverse",
      ease: "easeInOut"
    }
  }
};
