import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import { motion } from 'framer-motion';
import AnimatedText from './AnimatedText';

interface StyledAIProps {
  text: string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2';
}

export const StyledAI = ({ text, variant = 'body1' }: StyledAIProps) => {
  return (
    <Typography variant={variant} component="span">
      <AnimatedText
        text={text}
        isAI={true}
      />
    </Typography>
  );
};
