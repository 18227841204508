import { createTheme, ThemeOptions } from '@mui/material/styles';

// Extend the palette to include custom colors
declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      gradient1: string;
      gradient2: string;
      accent1: string;
      accent2: string;
      lightBg: string;
      darkBg: string;
    }
  }
  interface PaletteOptions {
    custom?: {
      gradient1: string;
      gradient2: string;
      accent1: string;
      accent2: string;
      lightBg: string;
      darkBg: string;
    }
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#8B4513', // Saddle brown
      light: '#A0522D', // Sienna for hover states
      dark: '#6B3811', // Darker brown for depth
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#DEB887', // Burlywood
      light: '#F5DEB3', // Wheat
      dark: '#D2B48C', // Tan
      contrastText: '#4A3219',
    },
    background: {
      default: '#FFFFFF',
      paper: '#FDF5E6', // Old lace
    },
    text: {
      primary: '#4A3219', // Dark brown
      secondary: '#8B4513', // Saddle brown
    },
    custom: {
      gradient1: 'linear-gradient(135deg, #8B4513 0%, #A0522D 100%)',
      gradient2: 'linear-gradient(135deg, #FDF5E6 0%, #DEB887 100%)',
      accent1: '#A0522D', // Sienna accent
      accent2: '#D2B48C', // Tan accent
      lightBg: '#FDF5E6',
      darkBg: '#6B3811',
    },
  },
  typography: {
    fontFamily: "'Inter', 'Roboto', sans-serif",
    h1: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 700,
      fontSize: '3.5rem',
      background: 'linear-gradient(135deg, #8B4513 0%, #A0522D 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      letterSpacing: '-0.02em',
    },
    h2: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600,
      fontSize: '2.5rem',
      color: '#8B4513',
    },
    h3: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600,
      fontSize: '2rem',
      color: '#8B4513',
    },
    h4: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      fontSize: '1.5rem',
      color: '#A0522D',
    },
    h5: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      fontSize: '1.25rem',
      color: '#A0522D',
    },
    h6: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      fontSize: '1rem',
      color: '#A0522D',
    },
    subtitle1: {
      fontFamily: "'Inter', sans-serif",
      fontSize: '1.125rem',
      lineHeight: 1.75,
      color: '#A0522D',
    },
    subtitle2: {
      fontFamily: "'Inter', sans-serif",
      fontSize: '0.875rem',
      lineHeight: 1.57,
      color: '#A0522D',
    },
    body1: {
      fontFamily: "'Inter', sans-serif",
      fontSize: '1rem',
      lineHeight: 1.75,
      color: '#A0522D',
    },
    body2: {
      fontFamily: "'Inter', sans-serif",
      fontSize: '0.875rem',
      lineHeight: 1.57,
      color: '#A0522D',
    },
    button: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600,
      fontSize: '0.875rem',
      textTransform: 'none',
      letterSpacing: '0.02em',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          padding: '8px 24px',
          transition: 'all 0.3s ease',
        },
        contained: {
          background: 'linear-gradient(135deg, #8B4513 0%, #A0522D 100%)',
          '&:hover': {
            background: 'linear-gradient(135deg, #A0522D 0%, #8B4513 100%)',
            transform: 'translateY(-2px)',
            boxShadow: '0 8px 20px rgba(139, 69, 19, 0.2)',
          },
        },
        outlined: {
          borderColor: '#8B4513',
          color: '#8B4513',
          '&:hover': {
            borderColor: '#A0522D',
            background: 'rgba(160, 82, 45, 0.05)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          boxShadow: '0 4px 20px rgba(139, 69, 19, 0.1)',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 8px 30px rgba(139, 69, 19, 0.15)',
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (min-width: 600px)': {
            paddingLeft: '32px',
            paddingRight: '32px',
          },
        },
      },
    },
  },
} as ThemeOptions);

export default theme;
