import { Container, Grid, Typography, Box, TextField, Button, MenuItem, Link, IconButton } from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { StyledAI } from '../components/StyledAI';
import { PageHeader } from '../components/PageHeader';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SupportIcon from '@mui/icons-material/Support';
import XIcon from '../components/icons/XIcon';
import { useState } from 'react';
import { sendContactForm } from '../services/emailService';

const MotionDiv = styled(motion.div)``;

const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  height: '100%',
  '& .MuiTypography-body1': {
    fontSize: '1.1rem',
    lineHeight: 1.7,
  },
}));

const QuoteBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  background: 'linear-gradient(145deg, rgba(52, 73, 94, 0.05) 0%, rgba(52, 73, 94, 0.1) 100%)',
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  fontStyle: 'italic',
  border: '1px solid rgba(52, 73, 94, 0.1)',
}));

const GradientTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 700,
  marginBottom: theme.spacing(3),
}));

const StyledSubheading = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1.75rem',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const EmailButton = styled(Button)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1.5),
  display: 'flex',
  justifyContent: 'flex-start',
  textTransform: 'none',
}));

const SocialIconButton = styled(IconButton)<{ component?: React.ElementType }>(({ theme }) => ({
  margin: theme.spacing(1),
  color: theme.palette.primary.main,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-3px)',
    color: theme.palette.primary.dark,
  },
}));

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    interest: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const result = await sendContactForm({
        name: formData.name,
        email: formData.email,
        message: `
Company: ${formData.company}
Phone: ${formData.phone}
Interest: ${formData.interest}
Message: ${formData.message}
        `.trim()
      });

      if (result.success) {
        // Show success message
        alert('Thank you for your message! We will get back to you soon.');
        setFormData({
          name: '',
          company: '',
          email: '',
          phone: '',
          interest: '',
          message: ''
        });
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Sorry, there was an error sending your message. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const interests = [
    'Enterprise Solutions',
    'Trading Algorithms',
    'Educational Technology',
    'Career Opportunities',
    'Partnerships',
    'Other'
  ];

  return (
    <Box>
      <PageHeader
        title="Start Your Tomorrow, Today"
        subtitle="Let's build the future together"
      />
      
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <MotionDiv variants={itemVariants}>
                <StyledSection>
                  <StyledSubheading variant="h4" align="center">
                    Every breakthrough begins with a conversation.
                  </StyledSubheading>
                  <StyledSubheading variant="h4" align="center">
                    Every innovation starts with a question.
                  </StyledSubheading>
                  <StyledSubheading variant="h4" align="center">
                    Every transformation opens with a simple "hello."
                  </StyledSubheading>
                </StyledSection>
              </MotionDiv>
            </Grid>

            <Grid item xs={12}>
              <MotionDiv variants={itemVariants}>
                <StyledSection>
                  <GradientTypography variant="h3">
                    Our Impact Speaks
                  </GradientTypography>
                  <QuoteBox>
                    <Typography variant="body1" sx={{ fontSize: '1.2rem' }}>
                      "CHHAAI didn't just provide AI solutions – they revolutionized how we think about technology. What used to take our team weeks now happens in hours. Their combination of military precision and educational clarity made our AI transformation seamless."
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2, textAlign: 'right' }}>
                      - Mike Posner, US Exporter
                    </Typography>
                  </QuoteBox>
                </StyledSection>
              </MotionDiv>
            </Grid>

            <Grid item xs={12} md={6}>
              <MotionDiv variants={itemVariants}>
                <StyledSection>
                  <GradientTypography variant="h3">
                    Let's Shape Your Future
                  </GradientTypography>
                  <StyledSubheading variant="h5" gutterBottom>
                    Schedule a Strategic Consultation
                  </StyledSubheading>
                  <Typography variant="body1" paragraph>
                    Connect directly with our Chief Marketing Officer, who brings global educational expertise to every conversation. Discover how our military-grade precision meets your business innovation needs.
                  </Typography>
                  <EmailButton
                    variant="contained"
                    color="primary"
                    startIcon={<EmailIcon />}
                    href="mailto:cmo@chhaai.com"
                  >
                    cmo@chhaai.com
                  </EmailButton>
                </StyledSection>
              </MotionDiv>
            </Grid>

            <Grid item xs={12} md={6}>
              <MotionDiv variants={itemVariants}>
                <StyledSection>
                  <GradientTypography variant="h3">
                    Choose Your Path
                  </GradientTypography>
                  
                  <StyledSubheading variant="h6" gutterBottom>Enterprise Innovation</StyledSubheading>
                  <Typography variant="body1" paragraph>Transform your large-scale operations</Typography>
                  <EmailButton
                    variant="outlined"
                    startIcon={<EmailIcon />}
                    href="mailto:support@chhaai.com"
                  >
                    support@chhaai.com
                  </EmailButton>

                  <StyledSubheading variant="h6" gutterBottom>Growth Acceleration</StyledSubheading>
                  <Typography variant="body1" paragraph>Scale your mid-sized business</Typography>
                  <EmailButton
                    variant="outlined"
                    startIcon={<EmailIcon />}
                    href="mailto:sales@chhaai.com"
                  >
                    sales@chhaai.com
                  </EmailButton>

                  <StyledSubheading variant="h6" gutterBottom>Startup Evolution</StyledSubheading>
                  <Typography variant="body1" paragraph>Begin your AI journey</Typography>
                  <EmailButton
                    variant="outlined"
                    startIcon={<EmailIcon />}
                    href="mailto:startup@chhaai.ai"
                  >
                    startup@chhaai.ai
                  </EmailButton>
                </StyledSection>
              </MotionDiv>
            </Grid>

            <Grid item xs={12} md={6}>
              <MotionDiv variants={itemVariants}>
                <StyledSection>
                  <GradientTypography variant="h3">
                    Quick Connect
                  </GradientTypography>
                  <Box sx={{ mb: 4 }}>
                    <StyledSubheading variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                      <LocationOnIcon sx={{ mr: 1 }} /> Visit Us
                    </StyledSubheading>
                    <Typography variant="body1">
                      CHHAAI AI Solutions Private Limited
                    </Typography>
                    <Typography variant="body1">
                      Indian Nagar 2nd street, Pudur
                    </Typography>
                    <Typography variant="body1">
                      Erode, Tamil Nadu, India
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 4 }}>
                    <StyledSubheading variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                      <AccessTimeIcon sx={{ mr: 1 }} /> Availability
                    </StyledSubheading>
                    <Typography variant="body1">
                      Monday - Friday: 9:00 AM - 6:00 PM IST
                    </Typography>
                    <Typography variant="body1">
                      Saturday: 9:00 AM - 1:00 PM IST
                    </Typography>
                  </Box>

                  <Box>
                    <StyledSubheading variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                      <SupportIcon sx={{ mr: 1 }} /> 24/7 Support
                    </StyledSubheading>
                    <Typography variant="body1">
                      AI-powered assistance always available
                    </Typography>
                    <Typography variant="body1">
                      Human Response time: &lt; 12 hours guaranteed
                    </Typography>
                  </Box>
                </StyledSection>
              </MotionDiv>
            </Grid>

            <Grid item xs={12} md={6}>
              <MotionDiv variants={itemVariants}>
                <StyledSection>
                  <GradientTypography variant="h3">
                    Start Your Journey
                  </GradientTypography>
                  <Typography variant="body1" paragraph>
                    Tell us about your vision, and we'll show you the future.
                  </Typography>
                  <form onSubmit={handleSubmit}>
                    <StyledTextField
                      required
                      fullWidth
                      label="Name"
                      variant="outlined"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    <StyledTextField
                      fullWidth
                      label="Company"
                      variant="outlined"
                      name="company"
                      value={formData.company}
                      onChange={handleChange}
                    />
                    <StyledTextField
                      required
                      fullWidth
                      label="Email"
                      type="email"
                      variant="outlined"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <StyledTextField
                      fullWidth
                      label="Phone"
                      variant="outlined"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    <StyledTextField
                      fullWidth
                      select
                      label="Your Interest"
                      variant="outlined"
                      name="interest"
                      value={formData.interest}
                      onChange={handleChange}
                    >
                      {interests.map((interest) => (
                        <MenuItem key={interest} value={interest}>
                          {interest}
                        </MenuItem>
                      ))}
                    </StyledTextField>
                    <StyledTextField
                      required
                      fullWidth
                      label="Message"
                      multiline
                      rows={4}
                      variant="outlined"
                      placeholder="Share your challenges. We'll craft your solutions."
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Sending...' : 'Submit'}
                    </Button>
                  </form>
                </StyledSection>
              </MotionDiv>
            </Grid>

            <Grid item xs={12}>
              <MotionDiv variants={itemVariants}>
                <StyledSection>
                  <Typography variant="h4" align="center" gutterBottom>
                    Connect With Us
                  </Typography>
                  <Box display="flex" justifyContent="center" mt={3}>
                    <Link href="https://www.linkedin.com/company/chhaai" target="_blank" rel="noopener noreferrer">
                      <SocialIconButton aria-label="LinkedIn">
                        <LinkedInIcon fontSize="large" />
                      </SocialIconButton>
                    </Link>
                    <Link href="https://x.com/chhaai" target="_blank" rel="noopener noreferrer">
                      <SocialIconButton aria-label="X (formerly Twitter)">
                        <XIcon fontSize="large" />
                      </SocialIconButton>
                    </Link>
                    <Link href="https://www.youtube.com/@chhaai" target="_blank" rel="noopener noreferrer">
                      <SocialIconButton aria-label="YouTube">
                        <YouTubeIcon fontSize="large" />
                      </SocialIconButton>
                    </Link>
                    <Link href="https://www.instagram.com/chhaai" target="_blank" rel="noopener noreferrer">
                      <SocialIconButton aria-label="Instagram">
                        <InstagramIcon fontSize="large" />
                      </SocialIconButton>
                    </Link>
                  </Box>
                </StyledSection>
              </MotionDiv>
            </Grid>

            <Grid item xs={12}>
              <MotionDiv variants={itemVariants}>
                <Typography variant="h5" sx={{ 
                  textAlign: 'center', 
                  fontStyle: 'italic', 
                  color: 'text.secondary',
                  background: 'linear-gradient(45deg, #34495E 30%, #5E8AAE 90%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}>
                  Where every connection leads to transformation – CHHAAI AI Solutions
                </Typography>
              </MotionDiv>
            </Grid>
          </Grid>
        </motion.div>
      </Container>
    </Box>
  );
};

export default Contact;
