// Force set document title
export const setTitle = () => {
  document.title = "Chhaai - Innovative Technology Solutions";

  // Override any attempts to change it
  Object.defineProperty(document, 'title', {
    get: function() {
      return "Chhaai - Innovative Technology Solutions";
    },
    set: function() {
      // Do nothing, keep our title
    }
  });
};

setTitle();
