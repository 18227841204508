import { Box, Container, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { StyledAI } from './StyledAI';

const HeaderContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
  background: theme.palette.custom.gradient2,
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(8),
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `linear-gradient(135deg, ${theme.palette.custom.accent1}15 0%, ${theme.palette.custom.accent2}15 100%)`,
    zIndex: 0,
  },
}));

const ContentWrapper = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  textAlign: 'center',
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  maxWidth: '800px',
  margin: '0 auto',
  marginTop: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

interface PageHeaderProps {
  title: ReactNode;
  subtitle?: string;
}

export const PageHeader = ({ title, subtitle }: PageHeaderProps) => {
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: 'easeOut'
      }
    }
  };

  return (
    <HeaderContainer>
      <Container maxWidth="lg">
        <ContentWrapper
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          {typeof title === 'string' ? (
            <StyledAI text={title} variant="h1" />
          ) : (
            title
          )}
          {subtitle && (
            <Subtitle variant="h5">
              {subtitle}
            </Subtitle>
          )}
        </ContentWrapper>
      </Container>
    </HeaderContainer>
  );
};
