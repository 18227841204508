import emailjs from '@emailjs/browser';

// Replace these with your EmailJS credentials
const SERVICE_ID = 'YOUR_SERVICE_ID';
const TEMPLATE_ID_CONTACT = 'YOUR_CONTACT_TEMPLATE_ID';
const TEMPLATE_ID_NEWSLETTER = 'YOUR_NEWSLETTER_TEMPLATE_ID';
const PUBLIC_KEY = 'YOUR_PUBLIC_KEY';

interface ContactFormData {
  name: string;
  email: string;
  message: string;
}

export const sendContactForm = async (data: ContactFormData) => {
  try {
    const response = await emailjs.send(
      SERVICE_ID,
      TEMPLATE_ID_CONTACT,
      {
        to_email: 'contact@chhaai.com',
        from_name: data.name,
        from_email: data.email,
        message: data.message,
      },
      PUBLIC_KEY
    );
    return { success: true, response };
  } catch (error) {
    console.error('Error sending contact form:', error);
    return { success: false, error };
  }
};

export const subscribeNewsletter = async (email: string) => {
  try {
    const response = await emailjs.send(
      SERVICE_ID,
      TEMPLATE_ID_NEWSLETTER,
      {
        to_email: 'contact@chhaai.com',
        subscriber_email: email,
      },
      PUBLIC_KEY
    );
    return { success: true, response };
  } catch (error) {
    console.error('Error subscribing to newsletter:', error);
    return { success: false, error };
  }
};
