import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import ChatbotService, { ChatMessage } from '../services/chatbotService';
import { useTheme } from '@mui/material/styles';

const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); opacity: 0.5; }
  50% { transform: scale(1.1); opacity: 0.8; }
  100% { transform: scale(1); opacity: 0.5; }
`;

const waveAnimation = keyframes`
  0% { transform: rotate(0deg); }
  10% { transform: rotate(14deg); }
  20% { transform: rotate(-8deg); }
  30% { transform: rotate(14deg); }
  40% { transform: rotate(-4deg); }
  50% { transform: rotate(10deg); }
  60% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
`;

const tooltipFadeIn = keyframes`
  0% { opacity: 0; transform: translateY(10px); }
  100% { opacity: 1; transform: translateY(0); }
`;

const ChatbotContainer = styled(motion.div)`
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
`;

const TooltipBubble = styled(motion.div)`
  position: absolute;
  bottom: 75px;
  right: 0;
  background: rgba(139, 69, 19, 0.95);
  color: #FDF5E6;
  padding: 8px 16px;
  border-radius: 12px;
  font-size: 14px;
  white-space: nowrap;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  animation: ${tooltipFadeIn} 0.3s ease-out;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -6px;
    right: 20px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid rgba(139, 69, 19, 0.95);
  }
`;

const ChatbotButton = styled(motion.div)<{ $bgColor: string }>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #FDF5E6;
  box-shadow: 0 4px 12px rgba(139, 69, 19, 0.3);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${floatAnimation} 3s ease-in-out infinite;
  position: relative;
  border: 2px solid #8B4513;
  transition: transform 0.3s ease;
  
  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    display: block;
    animation: ${waveAnimation} 3s ease-in-out 1s infinite;
    transform-origin: 70% 70%;
  }

  &:hover {
    background: #FFFFFF;
    transform: scale(1.05);
    
    & + ${TooltipBubble} {
      display: block;
    }
  }
`;

const PulseRing = styled.div<{ $borderColor: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #8B4513;
  animation: ${pulseAnimation} 2s infinite;
  opacity: 0.3;
`;

const ChatWindow = styled(motion.div)<{ $bgColor: string, $gradient: string }>`
  position: absolute;
  bottom: 80px;
  right: 0;
  width: 380px;
  height: 500px;
  background: #FDF5E6;
  border-radius: 20px;
  border: 2px solid #8B4513;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 8px 32px rgba(139, 69, 19, 0.15);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: #8B4513;
    border-radius: 18px 18px 0 0;
    z-index: 0;
  }
`;

const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(139, 69, 19, 0.2);
  position: relative;
  z-index: 1;

  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    display: block;
  }

  h3 {
    color: #FDF5E6;
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
  }
`;

const ChatMessages = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(139, 69, 19, 0.2);
    border-radius: 10px;
  }
`;

const Message = styled.div<{ isUser: boolean }>`
  max-width: 80%;
  padding: 12px 16px;
  border-radius: 15px;
  background: ${props => props.isUser ? 'rgba(139, 69, 19, 0.1)' : '#FDF5E6'};
  color: ${props => props.isUser ? '#4A3219' : '#8B4513'};
  align-self: ${props => props.isUser ? 'flex-end' : 'flex-start'};
  font-size: 0.95rem;
  box-shadow: 0 2px 4px rgba(139, 69, 19, 0.05);
  border: 1px solid ${props => props.isUser ? 'rgba(139, 69, 19, 0.1)' : 'rgba(139, 69, 19, 0.05)'};
`;

const ChatInput = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 15px;
  border-top: 1px solid rgba(139, 69, 19, 0.2);
  position: relative;
  z-index: 1;

  input {
    flex: 1;
    background: #FDF5E6;
    border: 1px solid rgba(139, 69, 19, 0.2);
    border-radius: 10px;
    padding: 12px 16px;
    color: #4A3219;
    font-size: 0.95rem;

    &::placeholder {
      color: rgba(139, 69, 19, 0.5);
    }

    &:focus {
      outline: none;
      border-color: rgba(139, 69, 19, 0.4);
      box-shadow: 0 0 0 2px rgba(139, 69, 19, 0.1);
    }
  }

  button {
    background: #8B4513;
    border: none;
    border-radius: 10px;
    padding: 12px 24px;
    color: #FDF5E6;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: #A0522D;
    }

    &:active {
      transform: scale(0.98);
    }
  }
`;

const InnovativeChatbot: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const theme = useTheme();
  const chatbotService = ChatbotService.getInstance();

  useEffect(() => {
    // Initial tooltip display
    setTimeout(() => setShowTooltip(true), 2000);

    // Set up periodic tooltip display
    const tooltipInterval = setInterval(() => {
      if (!isOpen) {  // Only show tooltip when chat is closed
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 5000);  // Hide after 5 seconds
      }
    }, 30000);  // Show every 30 seconds

    return () => clearInterval(tooltipInterval);
  }, [isOpen]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Add welcome message when chat opens
  useEffect(() => {
    if (isOpen && messages.length === 0) {
      setMessages([{
        text: "Hello! I'm your CHHAAI AI assistant. How can I help you today?",
        isUser: false
      }]);
    }
  }, [isOpen, messages.length]);

  const handleSend = async () => {
    if (!inputValue.trim()) return;

    console.log('Sending message:', inputValue);
    const userMessage = inputValue;
    setInputValue('');
    setMessages(prev => [...prev, { text: userMessage, isUser: true }]);
    setIsTyping(true);

    try {
      console.log('Calling chatbotService.sendMessage');
      const response = await chatbotService.sendMessage(userMessage);
      console.log('Received response:', response);
      setMessages(prev => [...prev, response]);
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prev => [...prev, {
        text: "I apologize, but I'm having trouble processing your request. Please try again later.",
        isUser: false
      }]);
    } finally {
      setIsTyping(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  return (
    <ChatbotContainer>
      <AnimatePresence>
        {showTooltip && !isOpen && (
          <TooltipBubble
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.3 }}
          >
            Need help? Chat with CHHAAI's AI Assistant!
          </TooltipBubble>
        )}
      </AnimatePresence>
      <ChatbotButton
        $bgColor={theme.palette.primary.main}
        onClick={() => {
          console.log('Chatbot button clicked');
          setIsOpen(!isOpen);
          setShowTooltip(false);
        }}
      >
        <img src="/chatbot_logo.svg" alt="CHHAAI AI Assistant" />
        <PulseRing $borderColor={theme.palette.primary.main} />
      </ChatbotButton>
      <AnimatePresence>
        {isOpen && (
          <ChatWindow
            initial={{ opacity: 0, scale: 0.9, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.9, y: 20 }}
            transition={{ type: "spring", damping: 20, stiffness: 300 }}
            $bgColor="#FDF5E6"
            $gradient={theme.palette.primary.main}
          >
            <ChatHeader>
              <img src="/chatbot_logo.svg" alt="CHHAAI AI Assistant" />
              <h3>CHHAAI Assistant</h3>
            </ChatHeader>

            <ChatMessages>
              {messages.map((message, index) => (
                <Message key={index} isUser={message.isUser}>
                  {message.text}
                </Message>
              ))}
              {isTyping && (
                <Message key="typing" isUser={false}>
                  <span>...</span>
                </Message>
              )}
              <div ref={messagesEndRef} />
            </ChatMessages>

            <ChatInput>
              <input
                type="text"
                value={inputValue}
                onChange={(e) => {
                  console.log('Input changed:', e.target.value);
                  setInputValue(e.target.value);
                }}
                onKeyPress={handleKeyPress}
                placeholder="Type your message..."
              />
              <button onClick={handleSend}>Send</button>
            </ChatInput>
          </ChatWindow>
        )}
      </AnimatePresence>
    </ChatbotContainer>
  );
};

export default InnovativeChatbot;
