import { Container, Grid, Typography, Box, Button } from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { StyledAI } from '../components/StyledAI';
import { PageHeader } from '../components/PageHeader';

const MotionDiv = styled(motion.div)``;

const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  height: '100%',
  '& .MuiTypography-body1': {
    fontSize: '1.1rem',
    lineHeight: 1.7,
  },
}));

const QuoteBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  background: 'linear-gradient(145deg, rgba(52, 73, 94, 0.05) 0%, rgba(52, 73, 94, 0.1) 100%)',
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  fontStyle: 'italic',
  border: '1px solid rgba(52, 73, 94, 0.1)',
}));

const GradientTypography = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(45deg, #34495E 30%, #5E8AAE 90%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 700,
  marginBottom: theme.spacing(3),
}));

const StyledSubheading = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1.75rem',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));

const Careers = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <Box>
      <PageHeader
        title="Moulding Tomorrow's Leaders, Today"
        subtitle="Join us in shaping the future of AI"
      />
      
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <MotionDiv variants={itemVariants}>
                <StyledSection>
                  <StyledSubheading variant="h4">
                    Meet Nivedhitha
                  </StyledSubheading>
                  <Typography variant="body1" paragraph>
                    Meet Nivedhitha, our Content Strategist. Her journey from a distinguished educator to an AI-enabled innovation leader mirrors the transformation we create at CHHAAI.
                  </Typography>
                  <QuoteBox>
                    <Typography variant="body1" sx={{ fontSize: '1.2rem' }}>
                      In Nivedhitha's words, "I remember staring at endless spreadsheets, spending weeks preparing financial reports. Today, those same tasks take minutes. But CHHAAI gave me more than just tools – they gave me vision. Now I'm part of an AI innovation team, exploring solutions I never imagined I'd create. What amazes me isn't just the technology, but how it empowers everyone here to innovate, regardless of their background. Recently, I helped develop an AI-powered reporting system that transformed our workflow. That's the CHHAAI difference – here, everyone is an innovator."
                    </Typography>
                  </QuoteBox>
                </StyledSection>
              </MotionDiv>
            </Grid>

            <Grid item xs={12}>
              <MotionDiv variants={itemVariants}>
                <StyledSection>
                  <StyledSubheading variant="h4">
                    A Day at CHHAAI
                  </StyledSubheading>
                  <Typography variant="body1" paragraph>
                    Imagine a workplace where every hour sparks brilliance. Your morning begins in our AI Innovation Lab, collaborating with brilliant minds of the world. By noon, you're brainstorming with international clients, while your code transforms businesses halfway across the globe.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Over lunch, casual conversations with pioneers turn into breakthrough strategies. You might find yourself using advanced AI tools months before they hit the market, or testing trading algorithms that process millions in microseconds. Your ideas don't wait in lengthy approval chains – they rocket from concept to implementation at lightning speed.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Afternoons buzz with cross-team innovation sprints. One moment, you're fine-tuning neural networks with AI engineers, the next you're strategizing with business leaders about tomorrow's tech horizon. Our Growth Partnership Program means you're always learning from the best – whether it's advanced algorithmic trading from our CEO or global education strategies from our CMO.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    As evening approaches, you might be pioneering new features for our AI platforms, or mentoring teammates on breakthrough technologies. Here, every project adds to your arsenal of cutting-edge skills. Every day accelerates your growth trajectory. Every moment shapes your future in tech leadership.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    In one day at CHHAAI, you'll experience more innovation, growth, and impact than most see in months elsewhere.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    We don't just offer jobs – we launch careers that define tomorrow's tech landscape.
                  </Typography>
                </StyledSection>
              </MotionDiv>
            </Grid>

            <Grid item xs={12} md={6}>
              <MotionDiv variants={itemVariants}>
                <StyledSection>
                  <StyledSubheading variant="h4">
                    The Spirit That Drives Us
                  </StyledSubheading>
                  <Typography variant="body1" paragraph>
                    Innovation lives in our DNA, but trust builds our foundation. We believe in ethical practices that stand the test of time. Our commitment goes beyond technology – it's about creating lasting impact with unwavering integrity.
                  </Typography>
                  <QuoteBox>
                    <Typography variant="body1" paragraph sx={{ fontStyle: 'italic' }}>
                      "Here, your ideas matter more than your designation." - Nivedhitha, Content Strategist
                    </Typography>
                    <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                      "The speed of innovation here isn't just about technology. It's about how quickly your ideas can impact the world" - Srivatsan, Technical Lead
                    </Typography>
                  </QuoteBox>
                </StyledSection>
              </MotionDiv>
            </Grid>

            <Grid item xs={12} md={6}>
              <MotionDiv variants={itemVariants}>
                <StyledSection>
                  <StyledSubheading variant="h4">
                    What We See in You
                  </StyledSubheading>
                  <Typography variant="body1" paragraph>
                    The future belongs to curious minds who dare to question the status quo.
                  </Typography>
                  <Typography variant="body1" component="div" sx={{ mb: 2 }}>
                    We seek individuals who:
                    <Box component="ul" sx={{ pl: 3, mt: 1 }}>
                      <Typography component="li" variant="body1">Think beyond boundaries and embrace continuous learning.</Typography>
                      <Typography component="li" variant="body1">See challenges as opportunities to innovate.</Typography>
                      <Typography component="li" variant="body1">Bring their authentic selves to work every day.</Typography>
                      <Typography component="li" variant="body1">Value trust and ethical practices as much as technological excellence.</Typography>
                    </Box>
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Your background matters less than your potential.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Your curiosity matters more than your current skills.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Your willingness to grow outweighs your existing knowledge.
                  </Typography>
                </StyledSection>
              </MotionDiv>
            </Grid>

            <Grid item xs={12} md={6}>
              <MotionDiv variants={itemVariants}>
                <StyledSection>
                  <StyledSubheading variant="h4">
                    Your Growth Journey
                  </StyledSubheading>
                  <Typography variant="body1" paragraph>
                    At CHHAAI, careers don't follow traditional paths – they forge new ones.
                  </Typography>
                  <Box component="ul" sx={{ pl: 3 }}>
                    <Typography component="li" variant="body1">Start in technical development, branch into AI innovation.</Typography>
                    <Typography component="li" variant="body1">Begin with content creation, evolve into strategy architecture.</Typography>
                    <Typography component="li" variant="body1">Launch from project management, soar into transformation leadership.</Typography>
                  </Box>
                </StyledSection>
              </MotionDiv>
            </Grid>

            <Grid item xs={12} md={6}>
              <MotionDiv variants={itemVariants}>
                <StyledSection>
                  <StyledSubheading variant="h4">
                    Ready to Shape Tomorrow?
                  </StyledSubheading>
                  <Typography variant="body1" paragraph>
                    Whether you're a seasoned tech professional or a curious innovator, CHHAAI offers more than a job. We offer the chance to be part of India's AI revolution.
                  </Typography>
                  <Box sx={{ mt: 3 }}>
                    <Button variant="contained" color="primary" size="large" sx={{ mr: 2 }}>
                      Explore Current Opportunities
                    </Button>
                  </Box>
                </StyledSection>
              </MotionDiv>
            </Grid>

            <Grid item xs={12}>
              <MotionDiv variants={itemVariants}>
                <StyledSection>
                  <StyledSubheading variant="h4">
                    Share Your Vision
                  </StyledSubheading>
                  <Typography variant="body1" paragraph>
                    Don't see a perfect role?
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Tell us how you'll contribute to our mission.
                  </Typography>
                  <Box sx={{ mt: 3 }}>
                    <Button variant="outlined" color="primary" size="large">
                      Open Application
                    </Button>
                  </Box>
                </StyledSection>
              </MotionDiv>
            </Grid>

            <Grid item xs={12}>
              <MotionDiv variants={itemVariants}>
                <Typography variant="h5" sx={{ 
                  textAlign: 'center', 
                  fontStyle: 'italic', 
                  color: 'text.secondary',
                  background: 'linear-gradient(45deg, #34495E 30%, #5E8AAE 90%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}>
                  Where potential meets purpose – CHHAAI AI Solutions
                </Typography>
              </MotionDiv>
            </Grid>
          </Grid>
        </motion.div>
      </Container>
    </Box>
  );
};

export default Careers;
