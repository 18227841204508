import React from 'react';
import { Box, Typography, Button, Container, Grid, Card, CardContent } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import AnimatedText from '../components/AnimatedText';

const StyledTagline = styled(Typography)(({ theme }) => ({
  fontSize: '3rem',
  fontWeight: 700,
  textAlign: 'center',
  marginBottom: theme.spacing(2),
  '& .highlight': {
    background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    display: 'inline-block',
  }
}));

const TaglineSection = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(8, 0),
  background: theme.palette.custom.gradient2,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `linear-gradient(135deg, ${theme.palette.custom.accent1}15 0%, ${theme.palette.custom.accent2}15 100%)`,
    zIndex: 0,
  },
  '& > *': {
    position: 'relative',
    zIndex: 1,
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(2),
  color: theme.palette.text.primary,
}));

const StyledSubheading = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1.75rem',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));

const Home = () => {
  const navigate = useNavigate();

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <Box>
      {/* Hero Section */}
      <Box
        component={motion.div}
        initial="hidden"
        animate="visible"
        sx={{
          minHeight: '90vh',
          display: 'flex',
          alignItems: 'center',
          background: 'linear-gradient(135deg, rgba(139, 69, 19, 0.05) 0%, rgba(210, 180, 140, 0.1) 100%)',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={8}>
              <motion.div variants={fadeIn} transition={{ duration: 0.8 }}>
                <Typography variant="h1" gutterBottom>
                  <AnimatedText text="Innovating Tomorrow's AI, Today" isAI={true} />
                </Typography>
                <Typography 
                  variant="h4" 
                  gutterBottom 
                  sx={{ 
                    color: 'text.secondary',
                    fontStyle: 'italic',
                    mb: 4 
                  }}
                >
                  Where military precision meets educational excellence to transform your future
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => navigate('/contact')}
                  sx={{ mt: 2 }}
                >
                  Transform Your Business →
                </Button>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Tagline Section */}
      <TaglineSection
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        <Container maxWidth="lg">
          <StyledTagline variant="h2">
            <AnimatedText text="Beyond AI. Beyond Innovation. Beyond Limits." isAI={true} />
          </StyledTagline>
          <Typography 
            variant="h5" 
            align="center" 
            color="text.secondary"
            sx={{ 
              fontStyle: 'italic',
              mt: 2 
            }}
          >
            Beyond AI. Beyond Innovation. Beyond Limits.
          </Typography>
        </Container>
      </TaglineSection>

      {/* Main Content */}
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid container spacing={6}>
          {/* Innovation Imperative */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <StyledSubheading variant="h4">
                  The Innovation Imperative
                </StyledSubheading>
                <Typography variant="body1" paragraph>
                  94% of businesses acknowledge AI as critical for success. Yet most struggle to harness its true potential. Every moment without strategic AI integration is an opportunity lost to market leaders.
                </Typography>
                <Typography variant="body1" paragraph>
                  In an era where tomorrow's innovations become today's essentials, will you lead or follow?
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Transformative Solutions */}
          <Grid item xs={12}>
            <StyledSubheading variant="h4">
              Transformative Solutions
            </StyledSubheading>
            <Typography variant="body1" paragraph>
              Your competitors are advancing. Technology is accelerating. The gap between AI leaders and followers widens every heartbeat.
            </Typography>
            <Typography variant="body1" paragraph>
              Time to transform. Time to lead. Time to thrive.
            </Typography>
          </Grid>

          {/* CHHAAI Edge */}
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <StyledSubheading variant="h4">
                  The CHHAAI Edge
                </StyledSubheading>
                <Typography variant="body1" paragraph>
                  Our unique blend of precision excellence and transformative innovation delivers:
                </Typography>
                <Typography component="ul">
                  <li>Revolutionary AI architectures</li>
                  <li>Groundbreaking solution frameworks</li>
                  <li>Unprecedented value creation</li>
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Transform Today */}
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <StyledSubheading variant="h4">
                  Transform Today
                </StyledSubheading>
                <Typography variant="body1" paragraph>
                  Watch your business evolve:
                </Typography>
                <Typography component="ul">
                  <li>From reactive analytics to predictive domination</li>
                  <li>From market presence to market leadership</li>
                  <li>From data processing to strategic brilliance</li>
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Innovation Pathways */}
          <Grid item xs={12}>
            <StyledSubheading variant="h4">
              Innovation Pathways
            </StyledSubheading>
            <Typography variant="body1" paragraph>
              Choose your transformation journey:
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <StyledSubheading variant="h5">
                      Enterprise Evolution
                    </StyledSubheading>
                    <Typography variant="body1">
                      Architect large-scale transformation with custom AI solutions that redefine industry standards.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <StyledSubheading variant="h5">
                      Growth Acceleration
                    </StyledSubheading>
                    <Typography variant="body1">
                      Harness AI-powered insights to outpace competition and capture market opportunities.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <StyledSubheading variant="h5">
                      Strategic Innovation
                    </StyledSubheading>
                    <Typography variant="body1">
                      Deploy cutting-edge trading algorithms and innovative technologies that set new benchmarks.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          {/* Impact Metrics */}
          <Grid item xs={12}>
            <StyledSubheading variant="h4" align="center">
              Impact Metrics
            </StyledSubheading>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={6} sm={3}>
                <Typography variant="h5" align="center">40%</Typography>
                <Typography variant="body1" align="center">Average Efficiency Gains</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography variant="h5" align="center">15+</Typography>
                <Typography variant="body1" align="center">Enterprise Solutions Deployed</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography variant="h5" align="center">Global</Typography>
                <Typography variant="body1" align="center">Market Impact</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography variant="h5" align="center">Leading</Typography>
                <Typography variant="body1" align="center">Innovation Index</Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Call to Action */}
          <Grid item xs={12} sx={{ textAlign: 'center', mt: 4 }}>
            <StyledSubheading variant="h4">
              Ready for Tomorrow?
            </StyledSubheading>
            <Box sx={{ mt: 3 }}>
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate('/contact')}
                sx={{ mr: 2, mb: 2 }}
              >
                Transform Your Business
              </Button>
              <Button
                variant="outlined"
                size="large"
                onClick={() => navigate('/services')}
                sx={{ mb: 2 }}
              >
                Explore Solutions
              </Button>
            </Box>
            <Typography 
              variant="body1" 
              sx={{ 
                mt: 4, 
                fontStyle: 'italic',
                color: 'text.secondary' 
              }}
            >
              Join us, forward-thinking organizations already revolutionizing their future with CHHAAI AI Solutions.
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ 
                mt: 2, 
                fontStyle: 'italic',
                color: 'text.secondary' 
              }}
            >
              Where Innovation Drives Tomorrow's Success
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Home;
