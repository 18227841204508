import { useState } from 'react';
import { AppBar, Toolbar, Box, IconButton, Drawer, useTheme, useMediaQuery, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { MotionBox } from '../../utils/motion';
import AnimatedText from '../AnimatedText';

const LogoContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  
  img {
    height: 48px;
    width: auto;
  }
`;

const StyledNavButton = styled(motion.button)(({ theme }) => ({
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  padding: theme.spacing(1, 2),
  color: theme.palette.text.primary,
  fontSize: '1.1rem',
  marginLeft: theme.spacing(3),
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const MobileMenu = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(8, 2),
  gap: theme.spacing(2),
}));

const navigationItems = [
  { label: 'Home', path: '/' },
  { label: 'About', path: '/about' },
  { label: 'Solutions', path: '/solutions' },
  { label: 'Team', path: '/team' },
  { label: 'Careers', path: '/careers' },
  { label: 'Contact', path: '/contact' },
];

export default function Header() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
    setMobileOpen(false);
  };

  return (
    <AppBar position="sticky" elevation={0} sx={{ backgroundColor: 'transparent', backdropFilter: 'blur(10px)' }}>
      <Toolbar>
        <LogoContainer
          onClick={() => handleNavigation('/')}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <img src="/logo.svg" alt="CHHAAI AI Logo" />
        </LogoContainer>

        <Box sx={{ flexGrow: 1 }} />

        {isMobile ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setMobileOpen(!mobileOpen)}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <Box component="nav">
            {navigationItems.map((item) => (
              <StyledNavButton
                key={item.path}
                onClick={() => handleNavigation(item.path)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {item.label}
              </StyledNavButton>
            ))}
          </Box>
        )}

        <Drawer
          anchor="right"
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
          PaperProps={{
            sx: {
              width: '100%',
              backgroundColor: 'transparent',
              backdropFilter: 'blur(20px)',
            },
          }}
        >
          <MobileMenu>
            <IconButton
              sx={{ position: 'absolute', top: 10, right: 10 }}
              onClick={() => setMobileOpen(false)}
            >
              <CloseIcon />
            </IconButton>
            {navigationItems.map((item) => (
              <StyledNavButton
                key={item.path}
                onClick={() => handleNavigation(item.path)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                sx={{ margin: '10px 0' }}
              >
                {item.label}
              </StyledNavButton>
            ))}
          </MobileMenu>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}
