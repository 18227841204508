import React from 'react';
import { Container, Grid, Typography, Box, Divider } from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { PageHeader } from '../components/PageHeader';
import { StyledAI } from '../components/StyledAI';
import AnimatedText from '../components/AnimatedText';

const MotionBox = styled(motion.div)`
  width: 100%;
`;

const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(4),
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 6px 25px rgba(0, 0, 0, 0.15)',
    background: `linear-gradient(to bottom right, ${theme.palette.background.paper}, rgba(255, 255, 255, 0.95))`,
  },
}));

const TeamAvatar = styled(Box)(({ theme }) => ({
  width: 200,
  height: 200,
  margin: '0 auto',
  marginBottom: theme.spacing(2),
  borderRadius: '50%',
  overflow: 'hidden',
  border: `4px solid ${theme.palette.background.paper}`,
  boxShadow: '0 4px 14px rgba(139, 69, 19, 0.2)',
  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
  },
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 20px rgba(139, 69, 19, 0.3)',
    '& img': {
      transform: 'scale(1.15)',
    },
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(6, 0),
  width: '100%',
}));

interface FadeInWhenVisibleProps {
  children: React.ReactNode;
}

const FadeInWhenVisible: React.FC<FadeInWhenVisibleProps> = ({ children }) => {
  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </MotionBox>
  );
};

const StyledHeading = styled(Typography)(({ theme }) => ({
  fontSize: '3rem',
  fontWeight: 700,
  textAlign: 'center',
  marginBottom: theme.spacing(2),
  '& .highlight': {
    fontSize: '3rem',
    background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    display: 'inline-block',
  }
}));

const TeamPage = () => {
  const introContent = [
    <AnimatedText key="intro1" text="In a world drowning in AI promises, most companies talk about the future. But who's actually building it? This question haunted two visionaries who saw a glaring gap in India's AI landscape – a gap between theoretical possibilities and practical transformation." isAI={true} />,
    <AnimatedText key="intro2" text="The problem wasn't just about technology. It was about trust, expertise, and the ability to deliver real-world impact. While countless startups promised AI solutions, businesses struggled to find partners who truly understood their challenges and could deliver measurable results." isAI={true} />,
    <AnimatedText key="intro3" text="That's when something extraordinary happened. Two visionaries came together with a singular mission: to build an AI powerhouse that wouldn't just develop solutions but would revolutionize how businesses evolve through AI" isAI={true} />
  ];

  const bridgeContent = [
    <AnimatedText key="bridge1" text="But they knew that two people, no matter how visionary, couldn't transform an industry alone. They needed a team of exceptional innovators – individuals who didn't just code or create, but who lived and breathed the future of AI. They needed pioneers who could translate precision and world-class excellence into groundbreaking AI solutions." isAI={true} />,
    "Today, that vision has materialized into CHHAAI's elite collective – a team where every member is not just an expert in their field but an architect of tomorrow's AI revolution.",
    "Meet the minds behind India's next AI unicorn:"
  ];

  const teamMembers = [
    {
      name: 'Rashul',
      title: 'Co-Founder and Chief Executive Officer',
      description: "Former Navy Commander with 14 years of distinguished service, Rashul brings military precision to AI innovation. Armed with master's degrees from elite institutions across the globe including IIT Delhi, IIT Kharagpur and Warwick University; plus an executive management course from IIM Lucknow, he fuses unparalleled technical expertise with strategic vision. Rashul is the driving force propelling our mission of AI excellence to new frontiers.",
      image: '/team/rashul.jpg'
    },
    {
      name: 'Arun',
      title: 'Co-Founder and Chief Marketing Officer',
      description: "Arun is a visionary educator who has revolutionized the learning experience for over 200,000 students worldwide. Renowned for his mastery in transforming complex concepts into accessible, actionable insights, he ensures our AI solutions are not just powerful but exceptionally user-friendly. With a relentless passion for innovation and a track record of transformative impact, Arun propels us to push the boundaries of what's possible in technology. Under his inspiring leadership, we're not just shaping the future—we're redefining it.",
      image: '/team/arun.jpg'
    },
    {
      name: 'Sharomena Aarthi',
      title: 'Vice President - People and Organization',
      description: "Sharomena Aarthi is an accomplished Quality and Regulatory Specialist with over a decade of expertise in ensuring compliance with EU Medical Device Regulations. Since beginning her career in 2013, she has demonstrated exceptional leadership and collaboration, working seamlessly with cross-functional teams—spanning design, risk engineering, clinical, and labeling—to align innovative solutions with stringent regulatory standards. Sharomena is renowned for her mastery in General Safety and Performance Requirements, Post Market Surveillance, and Technical Documentation. Her commitment to excellence is reflected in her stellar academic credentials, including a European Medical Device Regulation certification from the Global Institute of Regulatory Affairs, Pune, and a prestigious Silver Medal with an Elite Certificate from IITM, backed by the Ministry of HRD, Government of India. Beyond her technical prowess, Sharomena's ability to inspire and mentor teams sets her apart as a people-centric leader, adept at fostering collaboration and driving collective success. Passionate about tackling new challenges, she seeks opportunities that promote skill enhancement and innovation, making her a catalyst for organizational growth.",
      image: '/team/aarthi.jpg'
    },
    {
      name: 'Srivatsan',
      title: 'Technical Lead',
      description: "As our Technical Lead, Srivatsan brings cutting-edge expertise in AI and software development to CHHAAI. With a deep understanding of modern technologies and best practices, he leads our technical initiatives with precision and innovation. His expertise in building scalable solutions and implementing robust architectures ensures that our AI implementations meet the highest standards of excellence. Srivatsan's leadership in technical development and strategic vision plays a crucial role in driving CHHAAI's mission to deliver transformative AI solutions.",
      image: '/team/srivatsan.jpg'
    },
    {
      name: 'Azeem',
      title: 'Content Production Specialist',
      description: "Azeem is a visionary Content Production Specialist who seamlessly integrates technology and artistry to craft compelling visual narratives. With a foundation in hardware engineering from a prestigious institution and a passion for innovation, he brings a unique perspective to our creative endeavors. Azeem's expertise spans video editing, front-end development, and creative direction, enabling him to distill complex AI solutions into impactful stories that resonate with diverse audiences. His ability to translate intricate technical concepts into visually stunning and accessible content sets him apart as a true pioneer in the field. Driven by an unwavering commitment to excellence, Azeem's innovative approach amplifies our mission to make AI not only comprehensible but also transformative for businesses and communities worldwide.",
      image: '/team/azeem.jpg'
    },
    {
      name: 'Nivhedhitha',
      title: 'Content Strategist',
      description: "A pioneering voice in AI communication, Nivhedhitha brings exceptional skill in transforming complex technical concepts into compelling narratives that resonate with diverse audiences. Her expertise in content strategy and digital marketing ensures our innovative solutions are effectively communicated and understood.",
      image: '/team/nivhedhitha.jpg'
    }
  ];

  const openPositions = [
    'AI Engineering Pioneers',
    'Data Science Innovators',
    'Full-stack Development Visionaries',
    'Creative Technology Leaders',
    'Operations Excellence Specialists'
  ];

  return (
    <Box>
      <PageHeader
        title="Shaping Tomorrow's AI Pioneers, Today"
        subtitle="Where Vision Meets Innovation"
      />
      
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FadeInWhenVisible>
              <StyledSection>
                {introContent.map((paragraph, index) => (
                  <Typography key={index} variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                    {paragraph}
                  </Typography>
                ))}
              </StyledSection>
            </FadeInWhenVisible>
          </Grid>

          {teamMembers.slice(0, 2).map((member, index) => (
            <Grid item xs={12} key={member.name}>
              <FadeInWhenVisible>
                <StyledSection>
                  <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={3} sx={{ textAlign: 'center' }}>
                      <TeamAvatar>
                        <img src={member.image} alt={member.name} />
                      </TeamAvatar>
                      <Typography variant="h4" gutterBottom sx={{ fontSize: '1.75rem', fontWeight: 600 }}>
                        {member.name}
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                        {member.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                        {member.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </StyledSection>
              </FadeInWhenVisible>
            </Grid>
          ))}

          <Grid item xs={12}>
            <FadeInWhenVisible>
              <StyledSection>
                {bridgeContent.map((paragraph, index) => (
                  <Typography key={index} variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                    {typeof paragraph === 'string' ? (
                      <AnimatedText text={paragraph} isAI={true} />
                    ) : (
                      paragraph
                    )}
                  </Typography>
                ))}
              </StyledSection>
            </FadeInWhenVisible>
          </Grid>

          {teamMembers.slice(2).map((member, index) => (
            <Grid item xs={12} key={member.name}>
              <FadeInWhenVisible>
                <StyledSection>
                  <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={3} sx={{ textAlign: 'center' }}>
                      <TeamAvatar>
                        <img src={member.image} alt={member.name} />
                      </TeamAvatar>
                      <Typography variant="h4" gutterBottom sx={{ fontSize: '1.75rem', fontWeight: 600 }}>
                        {member.name}
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                        {member.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                        {member.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </StyledSection>
              </FadeInWhenVisible>
            </Grid>
          ))}

          <Grid item xs={12}>
            <FadeInWhenVisible>
              <StyledSection>
                <Typography variant="h3" gutterBottom sx={{ fontSize: '2rem', fontWeight: 600, textAlign: 'center' }}>
                  Join the Revolution
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                  Our team continues to grow as we push the boundaries of what's possible with AI. We're actively seeking exceptional talent across:
                </Typography>
                <Box sx={{ my: 4 }}>
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {openPositions.map((position, index) => (
                      <Typography
                        key={index}
                        component="li"
                        sx={{
                          fontSize: '1.1rem',
                          lineHeight: 2,
                          display: 'flex',
                          alignItems: 'center',
                          '&:before': {
                            content: '"•"',
                            color: 'primary.main',
                            fontWeight: 'bold',
                            marginRight: 2,
                          }
                        }}
                      >
                        {position}
                      </Typography>
                    ))}
                  </ul>
                </Box>
                <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                  Are you ready to shape the future of AI?
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.8, fontWeight: 500 }}>
                  Join the team that's not just predicting tomorrow – we're creating it.
                </Typography>
              </StyledSection>
            </FadeInWhenVisible>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default TeamPage;
